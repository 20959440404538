@media (min-width: 576px) {
    .col-sm-5 {
        flex: 0 0 auto;
        width: 20%;
    }
}

.font-weight-bold
{
    font-weight: 500 !important;
}

.font-weight-normal
{
    font-weight: normal !important;
}


label > input:checked + .btn {
    @extend .btn-primary;
}


.text-xl-nowrap {
    @include media-breakpoint-up(xl) {
        white-space: nowrap;
    }
}


.large-icon-message {
    min-height: 50vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 2.8125rem;

    .custom-logo svg {
        height: 4rem;
    }
}

.aspect-container {
  position: relative;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  > img, > video, > picture > img, > .aspect-subject {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.aspect-16-9 {
    &:before {
      padding-top: percentage( 9 / 16 );
    }
  }

  &.aspect-4-3 {
    &:before {
      padding-top: percentage( 3 / 4 );
    }
  }
}


.print-break-inside-avoid {
  @media only print {
    break-inside: avoid;
  }
}
