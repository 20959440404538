.driver-dashboard {
    h5 {
        font-size: 1rem !important;
    }
    .card-footer
    {
        background-color: unset !important;
    }
}
.order-card {

    .order-heading {
        font-size: .75rem !important;
        font-weight: 700 !important;
    }
    h5{
        font-size: 1rem !important;
        color: #333333 !important;
    }
}
