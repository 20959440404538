.compliance-check-group {
  @each $name, $color in $theme-colors {
    .group-#{$name} .custom-logo {
      background-color: rgba($color, .3);
    }
  }

  .custom-logo {
    position: absolute;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;


    svg {
      height: 48px;
    }
  }
}
