.btn-warning {
    color: #000 !important;
    background-color: var(--bs-warning) !important;
    border-color: var(--bs-warning) !important;
}

.btn-light {
    color: var(--bs-primary) !important;
    background-color: var(--bs-light) !important;
    border-color: var(--bs-light) !important;
}

.btn-link {
    color: var(--bs-primary) !important;
    background-color: var(--bs-white) !important;
    border-color: var(--bs-white) !important;
    font-weight: 400 !important;
    text-decoration: none;
}

.btn-success {
    color: #fff !important;
    background-color: $green-brand !important;
    border-color: $green-brand !important;
}

.btn-secondary {
    color: #fff !important;
    background-color: var(--bs-secondary) !important;
    border-color: var(--bs-secondary) !important;
}

.btn-danger {
    color: #fff !important;
    background-color: var(--bs-danger) !important;
    border-color: var(--bs-danger) !important;
}

.btn-outline-danger {
    color: var(--bs-danger) !important;
    border-color: var(--bs-danger) !important;

    &:hover {
        color: #fff !important;
        background-color: var(--bs-danger) !important;
        border-color: var(--bs-danger) !important;
    }
}

.btn-icon {
    --bs-btn-border-radius: 8px;

    .bi {
        font-size: 1.7rem;
    }

    &.btn-sm {
        .bi {
            font-size: 1.2em !important;
        }
    }

    .badge {
        position: absolute;
        top: -5px;
        right: -5px;
        z-index: 1;
        font-size: 0.7em;
    }
    &.p-1 {
        padding:  0.25rem 0.75rem !important;
    }
}

.btn {
    &.has-icon {
        .bi {
            margin-left: .5rem;
            font-size: 1.1em !important;
        }
    }
}

.btn-rounded {
    --bs-btn-border-radius: 50%;
    --bs-btn-padding-y: 0rem;
    --bs-btn-padding-x: 0.3rem;

    &.btn-sm {
        --bs-btn-padding-y: 0.1rem;
    }
}
