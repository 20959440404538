@media (max-width: 576px){
    .main-contents
    {
        > .card {
            margin: 2px !important;
            padding: 10px !important;
        }
    }
}

.react-bootstrap-table
{
    min-width: 100% !important;

    @media only screen {
        overflow-y: auto;
    }
}
