
@import '~react-pro-sidebar/dist/scss/styles.scss';
body {
    margin: 0;
    height: 100vh;
}

#root {
    height: 100%;
}

@include media-breakpoint-down(sm) {
    .pro-sidebar.md {
        width: 100vw !important;
        left: -100vw;
    }
}

.app {
    height: 100%;
    display: flex;
    position: relative;

    .btn-toggle {
        cursor: pointer;
        width: 35px;
        height: 35px;
        color: #fff;
        text-align: center;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        display: none;
    }

    .sidebar-btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .sidebar-btn {
            transition: width 0.3s;
            width: 150px;
            padding: 1px 15px;
            border-radius: 40px;
            background: rgba(255, 255, 255, 0.05);
            color: #adadad;
            text-decoration: none;
            margin: 0 auto;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-overflow: ellipsis;
            overflow: hidden;

            span {
                margin-left: 5px;
                font-size: 13px;
            }

            &:hover {
                color: $highlight-color;
            }
        }
    }

    .collapsed {
        .sidebar-btn {
            display: inline-block;
            line-height: 40px;
            padding: 0;
        }
    }

    main {
        padding: 0;

        @media only screen {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
}

.app.collapsed {
    .btn-toggle {
        display: flex !important;
    }

    .pro-sidebar:not(.toggled) {
        display: none;
    }

}

@media (max-width: 992px) {
    .app .btn-toggle {
        display: flex !important;
    }
}

.pro-sidebar {
    top: 52px;
    height: calc(100vh - 52px) !important;
    min-height: 400px !important;


    .pro-menu {
        margin: 0 12px !important;

        .pro-menu-item {
            border-radius: 8px !important;
            margin: 12px 0 !important;

            > .pro-inner-item {
                padding: 8px 15px 8px 20px !important;
            }
        }

        a {

        }
    }

    .badges {
        display: flex;

        justify-content: center;

        @include media-breakpoint-up(md) {
            justify-content: end;
        }
    }

    .badge {
        margin-right: .25rem;
    }

    .custom-logo {
        display: flex;

        svg {
            path {
                fill: #fff !important;
            }
        }

        &.menu-logo {
            svg {
                path {
                    stroke: #fff !important;
                    fill: none !important;
                }
            }
        }

        &.vehicle-icon {
            //stroke: #fff !important;
        }


        &.side-logout {
            svg {
                path {
                    fill: var(--bs-primary) !important;
                }
            }
        }
    }

    .active-link {

        background-color: #fff !important;
        color: var(--bs-primary) !important;

        .custom-logo {
            svg {
                path {
                    fill: var(--bs-primary) !important;
                }
            }

            &.menu-logo {
                svg {
                    path {
                        stroke: var(--bs-primary) !important;
                        fill: none !important;
                    }
                }
            }

            &.vehicle-icon {
                //stroke: var(--bs-primary) !important;
            }
        }

        &:hover {
            background-color: #fff !important;
            color: var(--bs-primary) !important;
        }

        a {
            color: white;

            &:hover {
                color: var(--bs-primary);
            }
        }

        &.pro-menu-item > {
            .pro-inner-item {
                &:hover {
                    color: var(--bs-primary);
                }
            }
        }
    }

    .grid-menu
    {
        svg {
            width: 40;
            height: 40;
        }
    }
}

@media (max-width: 575px){
    .pro-sidebar{
        .grid-menu
        {
            padding-bottom: 120px !important;
        }
    }
}
