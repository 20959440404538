.facility-name-site-card
{
    font-size: .75rem;
}
.facility-name-site-card-badge
{
    font-size: .5rem;
}

.site-card
{
    .facility-name-site-card
    {
        font-size: .75rem;
    }
    .facility-name-site-card-badge
    {
        font-size: .6rem;
    }
}

.site-name-distance
{
    p {
        font-size: 1rem;
    }
}
