.loading-holder {
    width: 100% !important;
    height: calc(100vh - 57px);
    background: #ffffff;
    //border: 1px solid red;
}

.clear-loading {
    width: 100% !important;
    height: calc(100vh - 57px);
    background: none !important;

    .loading-holder {
        background: none !important;
    }
}

.site-find-card {
    .loading-holder {
        position: absolute;
        top: 0;
        left: 0;
        opacity: .7 !important;
        height: 100% !important;
        z-index: 10;
    }
}

.card-has-loading {
    .loading-holder {
        position: absolute;
        top: 0;
        left: 0;
        opacity: .7 !important;
        height: 100% !important;
        z-index: 10;
    }
}
