.m-park-image-holder {
    background: #C4C4C4;
    margin: 0;
    height: 300px;
    width: 100%;
}

a {
    color: var(--bs-primary);
    text-decoration: none !important;
}


.m-container-left, .m-container-right {

    --container-width: 100vw;

    @each $size, $width in $container-max-widths {
        @include media-breakpoint-up($size) {
            --container-width: #{$width};
        }
    }
}

@each $size, $width in $grid-breakpoints {
    .m-container-#{$size}-left, .m-container-#{$size}-right {

        --container-width: 100vw;

        @each $size, $width in $container-max-widths {
            @include media-breakpoint-up($size) {
                --container-width: #{$width};
            }
        }
    }
}

#main-nav {
    @media only print {
        position: static;
    }
}

.m-container-left {
    margin-left: calc((100vw - var(--container-width) - var(--bs-gutter-x)) / 2);
}

.m-container-right {
    margin-right: calc((100vw - var(--container-width) - var(--bs-gutter-x)) / 2);
}

@each $size, $width in $grid-breakpoints {
    @include media-breakpoint-up($size) {
        .m-container-#{$size}-left {
            margin-left: calc((100vw - var(--container-width) - var(--bs-gutter-x)) / 2);
        }

        .m-container-#{$size}-right {
            margin-right: calc((100vw - var(--container-width) - var(--bs-gutter-x)) / 2);
        }
    }
}

.app-logo-container {
    left: 0;
    transition: all 0.2s ease;
}

.app-logo {
    width: 85px;
}


@media (max-width: 415px) {
    .app-logo-container {
        // left: 22px !important;
        // width: 180px !important;
    }
}

.custom-logo {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    svg {
        height: 20px;
        width: auto;
    }
}

.fixed-top .custom-logo {
    display: flex;
}

.elevated {
    z-index: 1;
}

.card-image {
    width: auto;
}

main > nav .btn {
    border-radius: 8px;
    padding: .3rem 1rem;
}

.subnav {
    @include media-breakpoint-up(sm) {
        flex: 1 1 1%;

    }
}

.content-holder {
    position: relative;
    transition: all ease-in 0.2s;
    z-index: 5;

    &.no-drawer {
        margin: 0 !important;
    }

    .main-contents {
        position: relative;
        padding-top: calc(52px + 16px);
        padding-bottom: 16px;

        .card {
            border: none !important;

            .card-footer {
                border: none !important;
                background-color: unset !important;
            }

            &.card-shadow {
                box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25) !important;
            }

            &.payment-card {
                border: 1px !important;
                box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25) !important;
            }
        }

        .form-control {
            box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
            border-radius: 8px;
        }

        .react-select {
            box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
            border-radius: 8px;

            > div {
                border-radius: 8px;
            }
        }

        .table {
            thead {
                background-color: $green-brand;
                color: #fff;
            }
        }

        .table-bordered > :not(caption) > * {
            border: none !important;
        }

        .table-bordered > :not(caption) > * > * {
            border: none !important;
        }

        .react-bs-table-sizePerPage-dropdown {
            display: none !important;
        }

        .page-item.active .page-link {
            background-color: var(--bs-primary);
            border-color: var(--bs-primary);
            color: #fff !important;
        }

        .page-link {
            color: var(--bs-primary) !important;
        }

        .page-item {
            &.active {
            }
        }
    }
}


.dash-card {
    border-radius: 8px;

    h3 {
        font-weight: 700;
    }
}

.bi {
    font-size: 1.5em;
}

.btn .bi {
    font-size: inherit;
}

.cursor-pointer {
    cursor: pointer;
}

.drawer-overlay {
    background-color: #1a202c;
    opacity: 0.3;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    transition: 1s;

    &.d-none {
        opacity: 0;
    }
}

.add-image-card {
    width: 100%;
    height: 100%;
    min-height: 100px;
}

.remove-image-holder {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    display: none;
    transition: all 0.2s;

    &:hover {
        opacity: 1;
        display: flex;
    }
}

#add-site-image {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.horizontal-check-labels {
    .form-check-input {
        margin-top: unset !important;
    }

    .form-check-label {
        margin-left: .5em !important;
        font-size: .8rem;
        word-break: keep-all;
        white-space: nowrap;
    }
}

.service-icon {
    width: 25px !important;
    height: 25px !important;
}

.address-icon {
    width: 40px !important;
    height: 30px !important;
}


.fleet-setting-card {
    min-height: calc(100vh - 300px) !important;
}

.gm-style-iw {
    .gm-style-iw-c {
        min-width: 300px !important;
    }
}

.gm-style {
    .gm-style-iw-c {
        min-width: 300px !important;
    }
}

.footer {
    position: relative;
    margin-top: auto;
    background-color: #fff;
    height: auto;
    min-height: 280px !important;
    width: 100%;
    background-image: url("../../assets/images/footer-frame.svg");
    background-repeat: no-repeat;
    background-position: top right; /* Center the image */
    background-size: cover; /* Resize the background image to cover the entire container */


    @media (min-width: 1645px) {
        min-height: 350px !important;
    }

    @media (min-width: 2060px) {
        min-height: 400px !important;
    }

    @media (min-width: 2370px) {
        min-height: 450px !important;
    }


    .footer-logo {
        height: 190px;
        z-index: 2;
    }

    .footer-frame {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .footer-contents {
        z-index: 2;
        position: absolute;
        bottom: 10px !important;
        width: 100%;
    }
}

@media (max-width: 576px) {
    .footer {
        .footer-logo {
            height: 150px;
            margin-bottom: 60px;
        }

        .footer-contents {
            background-color: $dark-blue-brand;
            bottom: -25px !important
        }
    }
}

