.login-content {
    label {
        color: #fff !important;
        font-size: 1.2rem !important;
    }

    .form-control {
        padding: 0.75rem 0.75rem !important;
        border: none !important;
        border-radius: 0.45rem !important;
    }

    a {
        color: #fff;
        text-decoration: none;
        font-weight: 400;

        &:hover {
            color: #fff;
            text-decoration: none;
        }

        &:visited {
            color: #fff;
            text-decoration: none;
        }

        &:active {
            color: #fff;
            text-decoration: none;
        }
    }
}

.pro-sidebar {
    > .pro-sidebar-inner {
        background: $dark-blue-brand !important;
    }
}
