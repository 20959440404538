

.booking {
  
  @include media-breakpoint-down(sm) {
    $booking-font-size:  1.4rem;

    @include font-size($booking-font-size);

    h1 { @include font-size($booking-font-size * 2.5); }
    h2 { @include font-size($booking-font-size * 2); }
    h3 { @include font-size($booking-font-size * 1.75); }
    h4 { @include font-size($booking-font-size * 1.5); }
    h5 { @include font-size($booking-font-size * 1.25); }
    h6 { @include font-size($booking-font-size); }

  }


}
