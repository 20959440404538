.login-bg, .player-wrapper, .login-gradient, .player-element {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
}

.login-bg {
    background-image: url("../../images/bg-cover.jfif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -3;
}

.player-wrapper {
    z-index: -2;
}

.player-element video {
    object-fit: cover;
}

.login-gradient {
    background: rgb(111, 221, 255);
    background: linear-gradient(180deg, rgba(111, 221, 255, 1) 0%, rgba(2, 138, 255, 1) 100%);
    opacity: .8;
    z-index: -1;
}

.login-content {

    &, .login-container > .row, .login-container {

        min-height: 100vh;
    }

    .login-container {
        .login-card {
            border: none !important;
            background: unset !important;

            .card-footer {
                border: none !important;
                background-color: unset !important;
            }
        }
    }
}
