
.attachments-input {
  border-radius: $border-radius;
  background-color: $white;

  border: $border-width solid $border-color;

  .row {
    min-height: 150px;
  }

  .row > * {
    max-width: 150px;
  }

  .attachment {
    position: relative;

    &-remove {
      position: absolute;
      z-index: 1;
      bottom: 0;
      right: 0;
      transform: translate(50%, 50%);
    }
  }

  > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

}
