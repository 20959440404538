label {
    width: 100%;
}

.filter-label {
    display: none !important;
}

.table {
    th {
        white-space: nowrap !important;
    }
    td {
        white-space: nowrap !important;
    }
}


.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.react-bootstrap-table-pagination-list {
    justify-content: right;

    .pagination {
        justify-self: right;
    }
}

.table-responsive {
    padding: 0 !important;
}

@media (min-width: 600px) {

    .react-bootstrap-table-pagination-list {
        .pagination {
            float: right !important;
        }
    }
}
