
@import "~quill/dist/quill.snow.css";

.wysiwyg {

  .ql-container {
    border-radius: $border-radius;
  }

  .ql-container,  .ql-toolbar {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  .ql-toolbar {
    background-color: $white;
  }

  .ql-toolbar + .ql-container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }


  &-upload {

    .ql-container {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .btn {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

  }

}


.wysiwyg-content {
  a {
    > img {
      @media only screen {
        width: 150px;
        height: 150px;
        object-fit: cover;

      }
    }
  }
}
