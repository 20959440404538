.react-calendar {
  border: none;

  &__tile {
    padding: 10px;

    &--active, &--hasActive {
      background-color: var(--bs-success);
    }
  }


  &__viewContainer {
    button {

      border-top: 1px solid $border-color;
      border-left: 1px solid $border-color;
    }
  }

  &__month-view__days,
  &__year-view__months,
  &__decade-view__years,
  &__century-view__decades, {
      border-bottom: 1px solid $border-color;
      border-right: 1px solid $border-color;
  }


  &__navigation {
    &__label {
      text-align: start;
      font-weight: 700;
      color: var(--bs-primary);
    }

    &__prev2-button, &__next2-button {
      display: none;
    }

    &__prev-button, &__next-button {
      order: 2;
    }

    button.react-calendar__navigation {
      &__prev-button, &__next-button {
        border: none;
        background-color: transparent;
        font-size: 1rem;
        min-width: 0;
        padding: 0 0.5rem;

        .bi {
          color: var(--bs-white);
          background-color: var(--bs-success);
          border-radius: 100%;
          font-size: inherit;
          width: 26px;
          height: 26px;
          text-align: center;
          line-height: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
