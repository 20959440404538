
.page-blocks {
  --primary-color: #{$primary};
  --secondary-color: #{$secondary};
  --primary-text-color: white;
  --secondary-text-color: white;
  --button-gutters: 1.6rem;
  --bs-font-sans-serif: Arial;

  margin-top: -1rem;

  h1, h2, h3, h4, h5 {
    font-weight: 700;
    color: var(--primary-color);
  }

  h1 {
    font-size: 2.5rem;

    @include media-breakpoint-up(md) {
      font-size: 3.375rem;
    }
  }


  h2 {
    font-size: 2rem;

    @include media-breakpoint-up(md) {
      font-size: 2.5rem;
    }
  }


  .btn-primary:not(.block-option) {
      --bs-btn-color: var(--primary-text-color);
      --bs-btn-bg: var(--primary-color);
      --bs-btn-border-color: var(--primary-color);
      // --bs-btn-hover-color: #fff;
      // --bs-btn-hover-bg: #00326f;
      // --bs-btn-hover-border-color: #002f68;
      // --bs-btn-focus-shadow-rgb: 38, 88, 149;
      // --bs-btn-active-color: #fff;
      // --bs-btn-active-bg: #002f68;
      // --bs-btn-active-border-color: #002c62;
      // --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  .btn-secondary:not(.block-option) {
      --bs-btn-color: var(--secondary-text-color);
      --bs-btn-bg: var(--secondary-color);
      --bs-btn-border-color: var(--secondary-color);
      // --bs-btn-hover-color: #fff;
      // --bs-btn-hover-bg: #00326f;
      // --bs-btn-hover-border-color: #002f68;
      // --bs-btn-focus-shadow-rgb: 38, 88, 149;
      // --bs-btn-active-color: #fff;
      // --bs-btn-active-bg: #002f68;
      // --bs-btn-active-border-color: #002c62;
      // --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  .edit-block {
    position: relative;
    min-height: 200px;
    background-color: #f0f0f0;

    &-add {
      min-height: 80px;
      background-color: transparent;
    }

    &-nav {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      display: flex;
      flex-direction: column;
      background-color: white;
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
      z-index: 100;

      .btn {
        border-radius:  100%;
        margin: 6px;
      }

      .dropdown-toggle:after, .dropdown-toggle:before {
        display: none;
      }
    }
  }


  .block {

    .buttons {

      .buttons-container {
        margin-left: calc(-0.5 * var(--button-gutters));
        margin-right: calc(-0.5 * var(--button-gutters));

      }

      .button-container {
        position: relative;
        display: inline-block;
        margin: calc(0.5 * var(--button-gutters));

        > .btn {
          @include media-breakpoint-up(md) {
            min-width: 200px;
          }

          @include media-breakpoint-up(xl) {
            min-width: 280px;
          }
        }

        .sort-handle {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(50%, -50%);
          z-index: 999;
          padding: 0.125rem 0.25rem;
        }
      }
    }

    .table thead {
      background-color: var(--primary-color);
      color: var(--primary-text-color);
    }


    &-addons, &-services {
      .addon {
        padding: 3.375rem;

        .name {
          font-weight: normal;
        }
      }

      h1, h2, h3, h4, h5 {
        color: var(--primary-text-color);
      }
    }


    &-addons {
      background-color: var(--primary-color);
      color: var(--primary-text-color);

      h1, h2, h3, h4, h5 {
        color: var(--primary-text-color);
      }
    }

    &-services, &-call-to-action {
      background-color: var(--secondary-color);
      color: var(--secondary-text-color);

      h1, h2, h3, h4, h5 {
        color: var(--secondary-text-color);
      }
    }

    &-pricing, &-media-with-content, &-details, &-gallery {
      color: var(--bs-body-color);
      background-color: transparent;
    }

    &-details {
      .bi {
        color: var(--primary-color);
      }

      .logo-container {
        img {
          max-height: 80px;
        }
      }

      .journey-details {
        @extend .text-muted;
      }

      .media-container {
        @include media-breakpoint-up (lg) {
          flex: 0 0 60.65%;
          width: 60.65%;
        }
      }

      &.media-right {
        .content-container {
          @include media-breakpoint-up (md) {
            padding-right: 2rem;
          }
        }

        .media-container {
          @include media-breakpoint-up (md) {
            overflow: hidden;
            border-top-left-radius: 5rem;
            border-bottom-left-radius: 5rem;
          }
        }
      }

      &.media-left {
        .content-container {
          @include media-breakpoint-up (md) {
            padding-left: 2rem;
          }
        }

        .media-container {
          @include media-breakpoint-up (md) {
            overflow: hidden;
            border-top-right-radius: 5rem;
            border-bottom-right-radius: 5rem;
          }
        }
      }

      .details-info-box {
        max-width: 288px;

        .text-ellipsis {
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

      }

      .btn-book-now {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;

        @include media-breakpoint-up (md) {
          margin-top: 3.375rem;
        }
      }

      h1 {
        margin-top: 1.5rem;

        @include media-breakpoint-up (md) {
          margin-top: 3.375rem;
        }
      }
    }

    &-media-with-content, &-details {
      &.media-right {
        .media-container {
          @include media-breakpoint-up(md) {
            order: 2;
          }
        }
      }
    }

    &-media-with-content {
      background-color: $gray-100;

      .row {
        @include media-breakpoint-up (xl) {
          --bs-gutter-x: 80px;
        }
      }

      .media-container {
        @include media-breakpoint-up (lg) {
          width: 48%;
        }

        img, video {
          border-radius: 40px;

          @include media-breakpoint-up (lg) {
            border-radius: 80px;
          }
        }
      }
    }

    &-call-to-action {
      .container {
        @include media-breakpoint-up (lg) {
          max-width: 835px;
        }
      }

      .buttons-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
      }
    }

    &-gallery {
      .gallery-items {
        margin-top: 0;
        margin-bottom: 40px;
        --bs-gutter-x: 40px;
        --bs-gutter-y: 40px;
      }

      .gallery-item {
        position: relative;
        display: block;
        width: 100%;
        overflow: hidden;
        border-radius: 40px;

        &:before {
          content: '';
          padding-top: 100%;
          display: block;
        }

        img, video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &-pricing {
      .container {
        @include media-breakpoint-up (lg) {
          max-width: 835px;
        }
      }
    }
  }
}

.media-selector {
  .media-item {
    position: relative;
    display: block;
    width: 100%;
    border: 1px solid $border-color;

    &:before {
      content: '';
      padding-top: 100%;
      display: block;
    }

    img, video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .spinner-border {
      position: absolute;
      top: calc(50% - var(--bs-spinner-height) / 2);
      left: calc(50% - var(--bs-spinner-width) / 2);
    }

    .custom-logo {
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }
}


.colour-pickers {
  .colour-sample {
    display: inline-block;
    height: 30px;
    width: 30px;
    border-radius: 100%;
  }
}
