.list-view-container::after {
    //content: '';
    //flex: auto;
}


.site-finder {
    &.map-view {
        @include media-breakpoint-down(lg) {


            .page-title, .results-summary {
                display: none;
            }

            .map-card-content {
                margin-left: -0.5rem;
                margin-right: -0.5rem;
                margin-top: -1rem;
            }
        }

        .map-card-content {
            > div {
                > div {
                    min-height: 500px !important;

                    @include media-breakpoint-down(lg) {
                        min-height: calc(100vh - 129px) !important;
                    }
                }
            }
        }
    }


    .view-controls {
        background-color: white;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999;
        display: flex;

        > * {
            flex-grow: 1;
            font-size: 1rem;
        }


        @include media-breakpoint-up(lg) {
            position: static;
            display: block;
            z-index: 1;
        }
    }

    .filter {
        background-color: white;
        position: fixed;
        display: none;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;

        z-index: 10000;

        @include media-breakpoint-up(lg) {
            overflow: visible;
            position: static;
            display: block;
        }
    }

    .filter {
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }

    .list-view-container {
        padding-bottom: 4rem;

        @include media-breakpoint-up(lg) {
            padding-bottom: 0;
       }
    }

    &.show-filter {
        .filter {
            display: block;
        }
    }
}
