.rc-slider-rail {
    height: 10px !important;
    background-color: #DEE2E6 !important;
}

.rc-slider-step {
    height: 10px !important;
}

.rc-slider-handle {
    width: 18px !important;;
    height: 18px !important;;
    border-color: var(--bs-primary) !important;
}

.rc-slider-track {
    height: 10px !important;
    background-color: var(--bs-primary) !important;
}
