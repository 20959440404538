
$primary: #003B82;
$dark-blue-brand: #00458C;
$green-brand: #136A4C;

$blue:    $dark-blue-brand;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   $green-brand;
$teal:    #20c997;
$cyan:    #0dcaf0;

$gray-100: #f9f9f9;

$border-radius: 0.5rem;
$border-radius-sm: 0.5rem;
$border-radius-lg: 0.5rem;

$input-btn-padding-y: .55rem;
$input-btn-padding-x: .75rem;
$btn-font-weight: 700;

//Bootstrap overrides
$grid-columns:      12;
$grid-gutter-width: 30px;

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / laptop
  lg: 992px,
  // Extra large screen / desktop
  xl: 1200px,
  // 2x Extra Large screen / wide desktop
  xxl: 1400px,
  // Huge screen / widest desktop
  hg: 1600px,
  // Gigantic Screen / 4K
  gt: 2100px,
);

$grid-max-breakpoints: (
  // Extra small screen / phone
  xs: 575px,
  // Small screen / phone
  sm: 767px,
  // Medium screen / tablet
  md: 991px,
  // Large screen / desktop
  lg: 1199px,
  // Extra large screen / wide desktop
  xl: 1399px,
  // 2x Extra Large screen / wide desktop
  xxl: 1599px,
  // Wide desktop
  hg: 2799px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1270px,
  hg: 1500px
);

$enable-negative-margins: true;

$sidebar-bg-color: #ECECEC;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(#0c1e35, 0.95);
$sidebar-color: #212529;
$highlight-color: #ECECEC;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;


$breakpoint-xs: map-get($grid-max-breakpoints, xs);
$breakpoint-sm: map-get($grid-max-breakpoints, sm);
$breakpoint-md: map-get($grid-max-breakpoints, md);
$breakpoint-lg: map-get($grid-max-breakpoints, lg);
$breakpoint-xl: map-get($grid-max-breakpoints, xl);
$breakpoint-xxl: map-get($grid-max-breakpoints, xxl);

@debug $breakpoint-xs;
